<script>
     import {minLength, numeric, required} from "vuelidate/lib/validators";
     import validationMessages from '@/components/validations'
     import Advertising from '@/services/Advertising';
     import Ticketing from "@/services/Ticketing";
     import Swal from "sweetalert2";
     import flatPickr from "vue-flatpickr-component";
     import "flatpickr/dist/flatpickr.css";


 export default {
        components:{ flatPickr,  validationMessages  },

        data() {
            return {
                events_setup:[],
                filterInputSetup: {
                  events_setup: '',
                },
                no_events: false,
                event_id_setup:'',
                gate_open:'',
                away:'',
                women:'',
                showModal: false,
                submitted: false,
                tryingToSubmit: false,
                showLoader: false,
                csrf_token: localStorage.getItem('csrf_token'),
                tryingToEdit: false,
                conf_gate_open: {
                    enableTime: true,
                    dateFormat: 'd-m-Y H:i'
                },
            };
        },

        validations: {
           event_id_setup: {
                required,
           },
           gate_open:{
                required,
           },
           women:{
                required,
           },
           away:{
               required,
           }
        },

       mounted() {


       },



        methods: {

            closeModal() {
                this.showModal = false;
            },

           async getAxessEventsToSetup(){

               try {
                    this.showLoader = true
                    var filter = 'gate_open=0';
                    await Ticketing.getAxessEvents(filter).then((response) => {
                        this.events_setup = response.data.data;
                        if(this.events_setup.length){
                            this.no_events = false;
                            this.filterInputSetup.events_setup = [''].concat.this.events_setup;
                        }else{
                            this.no_events = true;
                        }
                    }).catch(error => {
                        this.error = error.response.data.error ? error.response.data.error : "";
                        this.failedmsg("Can not get events")
                    }).finally(() => {
                        this.showLoader = false
                    })

                } catch (error) {
                   // this.error = error.response.data.error ? error.response.data.error : "";
                  //  this.failedmsg("Can not get events")
                }
            },


             async setupAxessEvent(){
                   this.tryingToEdit = true;
                   this.submitted = true;
                   this.$v.$touch()

                   if (this.$v.$invalid) {
                       this.tryingToEdit = false;
                       return;
                   } else {

                        try {
                            await Ticketing.setupAxessEvent({
                                      event_id: this.event_id_setup,
                                      gate_open: this.gate_open,
                                      away: this.away,
                                      women: this.women,
                                      csrf_token: this.csrf_token
                              }).then((response) => {

                               const error   =  typeof response.error !== 'undefined' ? response.error  : false;

                                if(error==''){
                                    this.successmsg("Successful setup!");
                                    this.closeModal();
                                }else{
                                    this.failedmsg("Failed to setup event!")
                                }

                            }).catch(error => {
                                this.error = error.response.data.error ? error.response.data.error : "";
                                this.failedmsg(this.error)
                            }).finally(() => {
                                this.refreshData()
                                this.tryingToSubmit = false;
                            })
                        } catch (error) {
                            this.error = error.response.data.error ? error.response.data.error : "";
                            this.failedmsg("Failed to setup event!");
                        }
                   }
                this.tryingToSubmit = false;
            },

             refreshData() {
                this.$emit('onRefresh') //event from parent
             }

        }
    };

</script>


<template>
      <b-modal id="axess_event_setup"  @shown="getAxessEventsToSetup" size="l" v-model="showModal" title="Setup Axess Event" title-class="font-18">
         <form @submit.prevent="setupAxessEvent()">
            <div class="row" >

             <div class="col-sm-12" v-show="no_events">
                No events to setup
             </div>

                <div class="col-sm-12" v-show="!no_events">
                      <b-form-group label="Select Event" label-for="event" class="mb-3">
                            <b-form-select  class="form-control" :class="{ 'is-invalid': submitted && $v.event_id_setup.$error}" v-model="event_id_setup" >
                                <option v-for="e in events_setup" :key="e.event_id" :value="e.event_id">{{e.event_name}} ({{e.event_date}})</option>
                            </b-form-select>
                            <validationMessages v-if="submitted" :fieldName="'Event'" :validationName="$v.event_id_setup"></validationMessages>
                      </b-form-group>


                       <b-form-group  label="Gate Open" label-for="gate_open" class="mb-3">
                         <flat-pickr
                              v-model="gate_open" :config="conf_gate_open"
                              :class="{ 'is-invalid': submitted && $v.gate_open.$error}"
                              class="form-control mb-2"
                              name="gate_open"
                         />
                         <validationMessages v-if="submitted" :fieldName="'Gate Open'" :validationName="$v.gate_open"></validationMessages>
                      </b-form-group>

                       <b-form-group label="Match" label-for="gate_open" class="mb-3">
                              <label class="radio-inline me-4"><input type="radio" name="away_match" value="no" v-model="away"> Home</label>
                              <label class="radio-inline me-4"><input type="radio"  name="away_match" value="yes" v-model="away">Away</label>
                              <validationMessages v-if="submitted" :fieldName="'Away'" :validationName="$v.away"></validationMessages>
                       </b-form-group>

                       <b-form-group label="Team" label-for="gate_open" class="mb-3">
                             <label class="radio-inline me-4"><input type="radio" name="women" value="no" v-model="women"> Men's</label>
                             <label class="radio-inline me-4"><input type="radio" name="women" value="yes" v-model="women">Women's</label>
                              <validationMessages v-if="submitted" :fieldName="'Women'" :validationName="$v.women"></validationMessages>
                       </b-form-group>

                        <input type="hidden" name="csrf_token" v-model="csrf_token">
                </div>


            </div>

        </form>

        <template v-slot:modal-footer>
            <b-button variant="light" @click="closeModal">Close</b-button>
            <b-button  v-show="!no_events" variant="primary" @click="setupAxessEvent" :disabled="tryingToSubmit || showLoader">
                 <b-spinner v-show="tryingToSubmit" small></b-spinner>Submit
            </b-button>
        </template>
    </b-modal>
</template>