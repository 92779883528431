<template>
    <b-dropdown class="d-inline-block columnVisibilityDD scroll-y" variant="primary" @click.stop>
        <template v-slot:button-content>
            <template name="button-content">
                Show Columns
                <i class="mdi mdi-chevron-down"></i>
            </template>
        </template>
        <b-dropdown-item-button v-for="field in fields" :key="field.value">
            <b-checkbox
                    :disabled="visFields.length == 1 && field.visible"
                    v-model="field.visible"
                    @click.stop
                    @click.native.stop
            >
                <span class="ms-1 mt-1">{{ field.text }}</span>
            </b-checkbox>
        </b-dropdown-item-button>
    </b-dropdown>
</template>

<script>
    export default {
        name: 'ColumnVisibility',
        props: {
            fields: {
                type: Array,
                required: true
            },
            visFields: {
                type: Array,
                required: true
            }
        }
    }
</script>