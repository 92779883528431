<script>
    import Layout from "../../layouts/main";
    import PageHeader from "@/components/page-header";
    import appConfig from "@/app.config";
    import Ticketing from "@/services/Ticketing";
    import ECharts from "vue-echarts";
    import "echarts/lib/chart/pie";
    import "echarts/lib/chart/bar";
    import "echarts/lib/component/legend";
    import "echarts/lib/component/title";
    import "echarts/lib/component/tooltip";
    import "echarts/lib/component/polar";
    import "echarts/lib/component/toolbox";
    import "echarts/lib/component/grid";
    import ColumnVisibilityV2 from '@/components/ColumnVisibilityV2';
    import countTo from "vue-count-to";

    import ModalAxessEventSetup from "@/components/modals/ticketing/modalAxessEventSetup";
    import ModalAxessEventLog from "@/components/modals/ticketing/modalAxessEventLog";
    import ExcelJS from "exceljs";
    import _ from "underscore";
    import Swal from "sweetalert2";


    export default {
        components: { Layout, PageHeader,  ColumnVisibilityV2,  ModalAxessEventSetup, ModalAxessEventLog, countTo},
        page: {
            title: "Axess Setup",
            meta: [
                {
                    name: "description",
                    content: appConfig.description,
                },
            ],
        },
        data() {
            return {
                error: null,
                tableData: [],
                title: "Axess Setup",
                items: [
                    {
                        text: "Ticketing",
                    },
                    {
                        text:"Axess Setup",
                        active: true,
                    }
                ],
             /*   filterInput: {
                    season: '',
                }, */
                 events:[],
                filterInput: {
                  events: '',
                },
                gate_open:'',
                away:'',
                women:'',
                event_id:'',
                log_data:[],
                send_to_axess_btn_show:false,
                send_to_axess_text_show: false,
                filterData: false,
                total_sold_cards: 0,
                total_subscription_tickets: 0,
                total_single_match_tickets: 0,
                total_scanned_tickets: 0,
                total_sent_rfid: 0,
                total_sent_single_match_tickets: 0,

                seasons: [],
                transactionData: [],
                subscriptionData:[],
                transactionId: '',
                totalPages: 0,
                totalItems: 0,
                currentPage: 1,
                perPage: 10,
                filter: '',
                sortBy: "date_created",
                sortDesc: true,
                showLoader:false,
                showLoader_c1:false,
                showLoader_c2:false,
                showLoader_c3:false,
                showLoader_c4:false,
                showLoader_c5:false,
                showLoader_c6:false,
                showLoader_c7:false,
                subscriptionPricing: null,
                subscriptionArea: null,
                subscriptionValidCards: 0,
                subscriptionUnassignedCards: 0,
                subscriptionOtherCards: 0,
                order_line_id:'',
                serieName:'',
                fields: [
                {
                     value: "product_type",
                     text: "Product Type",
                     visible: true
                   },
                   {
                     value: "barcode",
                     text: "Barcode",
                     visible: true
                   },
                   {
                     value: "area",
                     text: "Area",
                     visible: true
                   },
                   {
                     value: "row",
                     text: "Row",
                     visible: true
                   },
                   {
                     value: "seat",
                     text: "Seat",
                     visible: true
                   },
                   {
                     value: "tymes4_customer_number",
                     text: "Customer Number",
                     visible: true
                   },
                   {
                     value: "first_name",
                     text: "Name",
                     visible: true
                   },
                   {
                     value: "rfid_status",
                     text: "RFID Status",
                     visible: true
                   },
                   {
                     value: "serial_number",
                     text: "Serial Number",
                     visible: true
                   },
                   {
                     value: "scanned",
                     text: "Scanned",
                     visible: true
                   },
                ],
            };
        },
        async mounted() {

        },
        watch: {
            filter: {
                handler: _.debounce(function () {
                    // When the search input changes, load data from the backend
                    this.currentPage = 1;
                    this.getAxessScannerData()
                }, 1500),
                deep: true // Watch for changes inside the filter object
            },

            // Watch for changes in the season filter
            'filterInput.events': {
                handler: _.debounce(function () {
                    // When the season filter changes, load data from the backend
                    this.totalPages= 0
                    this.totalItems= 0
                    this.currentPage= 1
                    //this.getSeasonStatistics()
                }, 500),
            }
        },

        async created() {
            this.getAxessEvents();

        },

        methods: {

          async getAxessEvents(){
               try {
                    this.showLoader = true
                    var filter = 'gate_open=1';
                    await Ticketing.getAxessEvents(filter).then((response) => {
                        this.events = response.data.data;

                        if(this.events.length){
                            this.filterInput.events = [''].concat.this.events;
                        }
                    }).catch(error => {
                        this.error = error.response.data.error ? error.response.data.error : "";
                        this.failedmsg("Can not get events")
                    }).finally(() => {
                        this.showLoader = false
                    })

                } catch (error) {
                   // this.error = error.response.data.error ? error.response.data.error : "";
                  //  this.failedmsg("Can not get events")
                }
            },

            async getAxessEventData(){

                this.filterData = true;
                try {

                    var event_id = this.event_id;
                    var filter = 'event_id=' + this.event_id;
                    await Ticketing.getAxessEvents(filter).then((response) => {
                      var event_data = response.data.data[0];
                      this.gate_open = event_data.gate_open;
                      this.away = event_data.away;
                      this.women = event_data.women;
                      if(event_data.axess_setup == 1){
                        this.send_to_axess_text_show = true;
                        this.send_to_axess_btn_show = false;
                      } else {
                        this.send_to_axess_text_show = false;
                        this.send_to_axess_btn_show = true;
                      }

                    }).catch(error => {
                        this.error = error.response.data.error ? error.response.data.error : "";
                        this.failedmsg("Can not get event")
                    }).finally(() => {
                        this.showLoader = false
                    })

                } catch (error) {
                    this.error = error.response.data.error ? error.response.data.error : "";
                    this.failedmsg("Can not get event")
                }
            },

            async getAxessStatistics(){

                this.filterData = true;
                try {

                    this.showLoader_c2 = true;
                    var event_id = this.event_id;
                    var filter = 'event_id=' + this.event_id;
                    await Ticketing.getAxessStatistics(filter).then((response) => {
                      var statistics = response.data.data;

                      this.total_sold_cards = statistics.total_sold;
                      this.total_subscription_tickets = statistics.total_season;
                      this.total_single_match_tickets = statistics.total_single_match;
                      this.total_scanned_tickets = statistics.total_scanned;
                      this.total_sent_rfid = statistics.total_sent_rfid;
                      this.total_sent_single_match_tickets = statistics.total_sent_single_match;

                    }).catch(error => {
                        this.error = error.response.data.error ? error.response.data.error : "";
                        this.failedmsg("Can not get statistics");
                    }).finally(() => {
                       this.showLoader_c2 = false;
                    })

                } catch (error) {
                    this.error = error.response.data.error ? error.response.data.error : "";
                    this.failedmsg("Can not get event")
                }
            },

            async getAxessEventCronLog(){

                this.filterData = true;
                try {

                    var event_id = this.event_id;
                    var filter = 'event_id=' + this.event_id + '&limit=3';
                    await Ticketing.getAxessEventCronLog(filter).then((response) => {
                      var log_data = response.data.data;
                      this.log_data = log_data;

                    }).catch(error => {
                        this.error = error.response.data.error ? error.response.data.error : "";
                        this.failedmsg("Can not get event")
                    }).finally(() => {
                        this.showLoader = false
                    })

                } catch (error) {
                    this.error = error.response.data.error ? error.response.data.error : "";
                    this.failedmsg("Can not get event")
                }
            },

            callModalAxessEventSetup() {
                this.$bvModal.show("axess_event_setup");
            },

            callModalAxessEventLog() {
                this.$bvModal.show("axess_event_log");
            },

            failedmsg(msg) {
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: msg,
                    showConfirmButton: false,
                    timer: 50000,
                });
            },

            sendToAxess(event_id){
               Swal.fire({
                 title: "Are you sure?",
                 text: "Send Tickets to Axess",
                 icon: "question",
                 showCancelButton: true,
                 confirmButtonColor: "#34c38f",
                 cancelButtonColor: "#f46a6a",
                 confirmButtonText: "Yes!",
               }).then((result) => {
                 if (result.value) {
                    Ticketing.sendToAxess({"event_id":event_id})
                       .then((response) => {
                         const res   = response.data.data  ? response.data.data  : false;
                         const error = response.data.error ? response.data.error : '';
                         if(res && error==''){
                           this.getAxessEventData();
                           this.successmsg("Success!")
                         }else{
                           this.failedmsg("Fail!")
                         }
                       })
                       .catch(error => {
                         this.failedmsg('Fail!',error)
                   });
                 }
               });
             },





            async getAxessScannerData(){

                if(this.filterInput.season == ''){
                    this.showLoader_c7 = false
                    return false
                }

                const selectedSeasonId = this.filterInput.season;
                const selectedSeason = this.seasons.find(s => s.serie_id === selectedSeasonId);
                if (selectedSeason) {
                    this.serieName = selectedSeason.serie_name;
                }

                try {
                    this.showLoader_c7 = true
                    var filters = this.getFilters()
                    let paginationFilters = this.setPaginationFilters(filters)
                    const response = await Ticketing.getAxessScannerData(paginationFilters)
                    this.tableData = response.data.data
                    this.totalItems = response.data.total_items
                    this.totalPages = response.data.total_pages

                } catch (error) {
                    this.error = error.response.data.error ? error.response.data.error : "";
                    this.tableData = []
                }finally {
                    this.showLoader_c7 = false
                }
            },

            async getSubscriptionPricingData(){
                var filters = this.getFilters()
                const response = await Ticketing.getSubscriptionPricingData(filters)

                if(response.data.data){
                    this.subscriptionPricing = response.data.data
                    const keys = this.subscriptionPricing.map(item => item.type_name);
                    var arrValues = [];

                    this.subscriptionPricing.forEach((key) => {
                        arrValues.push({
                            value: key.sum, name : key.type_name
                        })
                    });

                    this.subscriptionPricing = {
                        visualMap: {
                            show: false,
                        },
                        tooltip: {
                            trigger: 'item',
                            formatter: '{a} <br/>{b} : {c} ({d}%)'
                        },
                        legend: {
                            orient: 'vertical',
                            left: 'left',
                            data: keys,
                            textStyle: {
                                color: '#999',
                                fontSize: 16,
                                fontWeight: 'bold',
                            }
                        },
                        color: ['#f46a6a', '#34c38f', '#50a5f1', '#f1b44c', '#556ee6'],
                        series: [
                            {
                                name: 'Subscription Pricing',
                                type: 'pie',
                                radius: '70%',
                                data: arrValues,
                                center: ['75%', '50%'],
                                itemStyle: {
                                    emphasis: {
                                        shadowBlur: 10,
                                        shadowOffsetX: 0,
                                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                                    }
                                }
                            }
                        ],
                        fontSize: 16
                    };
                }else{
                    this.subscriptionPricing = null
                }
            },

            async getSubscriptionAreaData(){
                var filters = this.getFilters()
                const response = await Ticketing.getSubscriptionAreaData(filters)

                if(response.data.data){
                    var subscriptionAreaData = response.data.data
                    const keys = subscriptionAreaData.map(item => item.area_name);
                    const values = subscriptionAreaData.map(item => item.vTotal);

                    this.subscriptionArea = {
                        width: '100%',
                        tooltip: {
                            trigger: 'item',
                            formatter: 'Area<br>{b} : {c}'
                        },
                        legend: {
                            orient: 'vertical',
                            right: 10,
                            top: 'center',
                        },
                        xAxis: {
                            type: 'category',
                            data: keys,
                            axisLabel: {
                                interval: 0,
                                align: 'center',
                                rotate: 30,
                                margin: 20,
                            }
                        },
                        yAxis: {},
                        series: [
                            {
                                type: 'bar',
                                data: values.map((value, index) => ({
                                    value,
                                    itemStyle: {
                                        color: ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'][index % 9]
                                    }
                                }))
                            }
                        ],
                        fontSize: 16,
                    };
                }else{
                    this.subscriptionArea = null
                }
            },




            getFilters(){
                var filter_string = '';
                if(this.event_id) {
                    filter_string += (this.event_id) ? '&event_id=' + this.event_id : "";
                }

                 //filter_string += '&event_id=6';

                return filter_string = filter_string.substring(1);
            },

            onDropdownHide(bvEvent){
                bvEvent.preventDefault();
            },
            handlePaginationChange(){
                this.getAxessScannerData()
            }
        },
        middleware: "authentication",
    };
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
            <div class="row text-end" >
                <div class="row">
                    <div class="col-12 col-md-12  ">
                      <a href="javascript:void(0);" class="btn btn-success waves-effect waves-light"
                           @click="callModalAxessEventSetup">
                          <i class="mdi mdi-plus me-2"></i>Event Setup
                      </a>
                  </div>
                </div>
            </div>



        <div class="row">
            <div class="col-12" v-if="!showLoader">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12 col-md-4 ">
                                <b-form-group label="Select Event" label-for="event" class="mb-3">
                                       <b-form-select  class="form-control"  v-model="event_id" >
                                           <option v-for="e in events" :key="e.event_id" :value="e.event_id">{{e.event_name}} ({{e.event_date}})</option>
                                       </b-form-select>

                                 </b-form-group>

                               <div class="row mt-4">
                                   <div class="col d-inline-flex">
                                     <button type="button" class="btn btn-success me-2 w-lg" @click="getAxessEventData();getAxessEventCronLog();getAxessScannerData();getAxessStatistics()">Filter</button>

                                     <button type="button" class="btn btn-primary w-lg" @click="resetFilters">Reset</button>
                                   </div>
                                </div>
                            </div>


                            <div class="col-12 col-md-1" >
                            </div>

                             <div class="col-12 col-md-7 text-center" v-show="filterData">
                                <h6>Event Setup Info</h6>
                                <div class="row text-center">
                                    <div class="col-12 col-md-3">
                                        <span class="text-strong">Gate Open:</span><p>{{gate_open}}</p>
                                    </div>

                                    <div class="col-12 col-md-2">
                                        <span class="text-strong">Away: </span><p>{{away}}</p>
                                    </div>

                                    <div class="col-12 col-md-2">
                                        <span class="text-strong">Women: </span><p>{{women}}</p>
                                    </div>

                                    <div class="col-12 col-md-5">
                                        <span class="text-strong">Sent to Axess Status:</span>
                                        <br>
                                         <a v-if="send_to_axess_btn_show" href="javascript:void(0);" class="btn btn-success waves-effect waves-light text-start"
                                              @click="sendToAxess(event_id)">
                                               Send Tickets to Axess
                                         </a>
                                         <span v-if="send_to_axess_text_show" class="text-start">
                                              <ul>
                                                 <li v-for="(item, index) in log_data" :key="index">{{ item.log }}</li>
                                              </ul>
                                              <a  href="javascript:void(0);" class="btn btn-success waves-effect waves-light"
                                                  @click="callModalAxessEventLog">
                                                  More Details >>
                                              </a>
                                         </span>
                                    </div>

                                </div>

                             </div>

                        </div>
                    </div>
                </div>

                <div class="card" v-show="filterData">
                    <div class="card-body">
                        <h4 class="text-center">Statistics</h4>
                    </div>
                    <div class="card-body">

                        <div class="row">
                            <div class="custom-accordion">
                                <a
                                        class="text-body fw-semibold pb-2 d-block"
                                        data-toggle="collapse"
                                        href="javascript: void(0);"
                                        role="button"
                                        aria-expanded="false"
                                        v-b-toggle.numberCharts-collapse
                                >
                                    <i class="mdi mdi-chevron-up accor-down-icon text-primary me-1"></i>Numbers and Charts
                                </a>
                                <b-collapse visible id="numberCharts-collapse">
                                    <div class="row mb-5 mt-5 mb-5 d-flex justify-content-around">



                                    <div class="col-md-4">
                                        <div class="card bg-gradient-light h-100">
                                            <div class="card-body d-grid" >
                                                <div>
                                                    <h5 class="text-muted mb-0 text-center">Total Subscription Tickets</h5>
                                                </div>
                                                <h4 class="mt-3 align-self-end text-center">
                                                    <span data-plugin="counterup" v-if="!showLoader_c2">
                                                        <countTo :startVal="0" :endVal="total_subscription_tickets" :duration="2000"></countTo>
                                                    </span>
                                                    <div class="container-fluid d-flex justify-content-center align-items-center h-50" v-else>
                                                        <b-spinner large></b-spinner>
                                                    </div>
                                                </h4>
                                            </div>
                                        </div>
                                    </div>



                                    <div class="col-md-4">
                                        <div class="card bg-gradient-light h-100">
                                            <div class="card-body d-grid" >
                                                <div>
                                                    <h5 class="text-muted mb-0 text-center">Total Single Match Tickets</h5>
                                                </div>
                                                <h4 class="mt-3 align-self-end text-center">
                                                    <span data-plugin="counterup"  v-if="!showLoader_c2">
                                                        <countTo :startVal="0" :endVal="total_single_match_tickets" :duration="2000"></countTo>
                                                    </span>
                                                    <div class="container-fluid d-flex justify-content-center align-items-center h-50" v-else>
                                                        <b-spinner large></b-spinner>
                                                    </div>
                                                </h4>
                                            </div>
                                        </div>
                                    </div>

                                     <div class="col-md-4">
                                        <div class="card bg-gradient-light h-100">
                                            <div class="card-body d-grid" >
                                                <div>
                                                    <h5 class="text-muted mb-0 text-center">Total Sold Tickets</h5>
                                                </div>
                                                <h4 class="mt-3 align-self-end text-center">
                                                    <span data-plugin="counterup" v-if="!showLoader_c2">
                                                        <countTo :startVal="0" :endVal="total_sold_cards" :duration="2000"></countTo>
                                                    </span>
                                                    <div class="container-fluid d-flex justify-content-center align-items-center h-50" v-else>
                                                        <b-spinner large></b-spinner>
                                                    </div>
                                                </h4>
                                            </div>
                                        </div>
                                    </div>


                                    <div class="col-md-4">
                                        <div class="card bg-gradient-light h-100">
                                            <div class="card-body d-grid" >
                                                <div>
                                                    <h5 class="text-muted mb-0 text-center">Total Sent RFID</h5>
                                                </div>
                                                <h4 class="mt-3 align-self-end text-center">
                                                    <span data-plugin="counterup" v-if="!showLoader_c2">
                                                        <countTo :startVal="0" :endVal="total_sent_rfid" :duration="2000"></countTo>
                                                    </span>
                                                    <div class="container-fluid d-flex justify-content-center align-items-center h-50" v-else>
                                                        <b-spinner large></b-spinner>
                                                    </div>
                                                </h4>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-4">
                                        <div class="card bg-gradient-light h-100">
                                            <div class="card-body d-grid" >
                                                <div>
                                                    <h5 class="text-muted mb-0 text-center">Total Sent Single Match Tickets</h5>
                                                </div>
                                                <h4 class="mt-3 align-self-end text-center">
                                                    <span data-plugin="counterup" v-if="!showLoader_c2">
                                                        <countTo :startVal="0" :endVal="total_sent_single_match_tickets" :duration="2000"></countTo>
                                                    </span>
                                                    <div class="container-fluid d-flex justify-content-center align-items-center h-50" v-else>
                                                        <b-spinner large></b-spinner>
                                                    </div>
                                                </h4>
                                            </div>
                                        </div>
                                    </div>

                                      <div class="col-md-4">
                                        <div class="card bg-gradient-light h-100">
                                            <div class="card-body d-grid" >
                                                <div>
                                                    <h5 class="text-muted mb-0 text-center">Total Scanned Tickets</h5>
                                                </div>
                                                <h4 class="mt-3 align-self-end text-center">
                                                     <span data-plugin="counterup"  v-if="!showLoader_c2">
                                                        <countTo :startVal="0" :endVal="total_scanned_tickets" :duration="2000"></countTo>
                                                     </span>
                                                    <div class="container-fluid d-flex justify-content-center align-items-center h-50" v-else>
                                                        <b-spinner large></b-spinner>
                                                    </div>
                                                </h4>
                                            </div>
                                        </div>
                                    </div>


                                </div>


                                </b-collapse>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="card" v-show="filterData">
                    <div class="card-body">

                        <div class="col-md-6">
                            <ColumnVisibilityV2 class="me-2" :fields="fields" :visFields="visibleFields"></ColumnVisibilityV2>

                        </div>

                        <!-- Table -->
                         <div class="table-responsive mb-0" >
                            <v-app>
                                <v-data-table
                                    :headers="visibleFields"
                                    :items="tableData"
                                    :search="filter"
                                    :items-per-page="perPage"
                                    :page="currentPage"
                                    :server-items-length="totalItems"
                                    :hide-default-footer="true"
                                    @update:page="onPageChange"
                                    @update:items-per-page="onItemsPerPageChange"
                                    @update:sort-desc="updateSortDesc"
                                    @update:sort-by="updateSortBy"
                                >

                                    <template v-slot:item.first_name="{ item }">
                                        {{ item.first_name + ' ' + item.middle_name + ' ' + item.last_name }}
                                    </template>

                                     <template v-slot:item.barcode="{ item }">
                                        {{ item.barcode }}
                                        <i v-if="item.check_status ==='A'"  class="fas fa-check" title="Allowed" style="color:green"></i>
                                        <i v-if="item.check_status ==='B'"  class="fas fa-times" title="Blocked" style="color:red"></i>
                                        <i v-if="item.check_status ==='U'"  class="fas fa-question" title="Unknown" style="color:yellow"></i>
                                        <i v-if="item.check_status ==='E'"  class="fas fa-times" title="Error" style="color:red"></i>
                                     </template>

                                    <template v-slot:footer>
                                        <v-row v-if="showLoader_c7" class="mx-4">
                                            <v-col class="text-center">
                                                <v-progress-linear indeterminate color="primary"></v-progress-linear>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col >
                                                <v-pagination v-model="currentPage" :length="totalPages" :total-visible="6" @input="onPageChange" circle></v-pagination>
                                            </v-col>
                                        </v-row>
                                    </template>

                                    <template v-slot:top="{ pagination, options, updateOptions }">
                                        <v-row>
                                            <v-col md="6" sm="12">
                                                <v-data-footer :pagination="pagination" :options="options" @update:options="updateOptions" items-per-page-text="$vuetify.dataTable.itemsPerPageText" :items-per-page-options="itemsPerPageOpts"/>
                                            </v-col>
                                            <v-col md="6" sm="12">
                                                <v-text-field v-model="filter" :append-icon="filter ? 'mdi-close' : 'mdi-magnify'" @click:append="clearSearch" label="Search" single-line hide-details outlined dense></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </template>

                                </v-data-table>
                            </v-app>
                        </div>

                    </div>
                </div>
            </div>
            <div class="card-body d-flex align-items-center justify-content-center" v-else>
                <b-spinner large></b-spinner>
            </div>
        </div>

        <!-- MODALS -->

        <ModalAxessEventSetup @onRefresh="getAxessEvents()"></ModalAxessEventSetup>
        <ModalAxessEventLog @onRefresh="callModalAxessEventLog()" :event_id="event_id"></ModalAxessEventLog>
        <!-- /MODALS -->

    </Layout>
</template>
