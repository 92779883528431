<script>

 import Ticketing from "@/services/Ticketing";

 export default {
        components:{  },
        props: ['event_id'],

        data() {
            return {
                showModal: false,
                showLoader: false,
                log_data:''
            };
        },


        methods: {

            closeModal() {
                this.showModal = false;
            },

           async getAxessEventCronLog(){

                    this.filterData = true;
                    try {
                        this.showLoader = true;
                        var event_id = this.event_id;
                        var filter = 'event_id=' + this.event_id;
                        await Ticketing.getAxessEventCronLog(filter).then((response) => {
                          var log_data = response.data.data;
                          this.log_data = log_data;

                        }).catch(error => {
                            this.error = error.response.data.error ? error.response.data.error : "";
                            this.failedmsg("Can not get event")
                        }).finally(() => {
                            this.showLoader = false
                        })

                    } catch (error) {
                        this.error = error.response.data.error ? error.response.data.error : "";
                        this.failedmsg("Can not get event")
                    }
                },

           },


    };

</script>


<template>
      <b-modal id="axess_event_log"  @shown="getAxessEventCronLog" size="xl" v-model="showModal" title="Log History" title-class="font-18"  >
          <a href="javascript:void(0);" class="btn btn-success waves-effect waves-light"
               @click="getAxessEventCronLog">Refresh Data
               <i class="fas fa-sync-alt"></i>
          </a>
         <div  v-if="!showLoader" class="modal-body" style="max-height: 350px; overflow-y: auto;" >
            <div class="row" >
                <div class="col-sm-12">
                    <table class="table">
                    <thead>
                        <th>Datetime</th>
                        <th>Event</th>
                        </thead>
                        <tbody>
                           <tr v-for="(item, index) in log_data" :key="index">
                             <td>{{ item.timestamp }}</td>
                             <td>{{ item.log }}</td>
                           </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

         <template v-slot:modal-footer>
                    <b-button variant="light" @click="closeModal">Close</b-button>
                </template>
    </b-modal>
</template>